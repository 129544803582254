.App {
  text-align: center;

}

text {
  font-size: 25px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.column {
  width: 1000px;
  height: 500px;
  background:  #0069ed;
  display: flex; 
}

.textwithphoto{
  display: flex;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
