
.PageHeader {
  background-color: #28303d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height:100%;
}

.titlefont{
  font-family: Georgia, sans-serif;
  font-size: 4em;
  text-align: center;
}

h1,h2,h3,p{
  font-family: Georgia, sans-serif;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  text-align: center;
  
}

h1{
  font-size: 3em;
}

h2{
  font-size: 2em;
}

h3{
  font-size: 1.5em;
}

.image{
  width: 25%;
  align-items: center;
}

.textbox {
  width:80%;
  height: auto;
  background: #39414d;  
  display: flex;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  border-radius:1cm;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10;
  align-content: center;
  align-self: center;
  text-align: center;
}

.columntextbox {
  width: 100%;
  height: auto;
  background: #39414d;  
  display: flex;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  border-radius:1cm;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10;
  align-content: center;
  align-self: center;
  text-align: center;
}