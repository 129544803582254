.navbutton {
  display:flex;
  width: 100%;
  max-width: 290px;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
  min-height: 90px;
  background:	#FFFFF0;
  color: black;
  display: inline-block;
  border-radius: 5000px;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-weight:400;
  font-size: 1em;
}

.navlink{
  color: black;
  padding: 2em;
  text-decoration: none;
  margin: -2em;
}
